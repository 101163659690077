@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box; }

.auth-container {
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  align-items: center;
  justify-content: center;

  .auth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .auth-brand-logo {
      img {
        width: 250px; } }

    .auth-form {
      width: 250px; }

    .auth-link {
      width: 250px;
      margin-top: .5rem; } } }

.app-dl-dt-display {
  dl {
    font-family: "Roboto";
    float: left;
    width: 100%;
    padding: 1px; }
  dt {
    float: left;
    width: 30%;
    min-height: 25px;
    line-height: 25px;
    color: #889096;
    text-shadow: 0 1px 1px white;
    border-right: 1px solid #e2e6e8;
    padding-right: .8rem;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  dd {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: .8rem;
    padding-left: .8rem;
    min-height: 25px;
    line-height: 25px; } }
